import { ChangeDetectorRef, Component, ElementRef, Renderer2, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from './core/services/shared.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CommonService } from './core/services/common.service';
import { environment } from '../environments/environment';
import { SchemasService } from './core/services/schemas.service';

declare const $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isOpened: boolean = false;
  showCountries: boolean = false;
  isSkillGroup:boolean;
  selectedDeveloperWorkId : any;
  showProfile: boolean = false;
  selectedSkills: any[] =[];
  title = 'aumnics-website';
  domain = 'amy.pro';
  showSearch = false;
  isNotBeaveBrowser = false;
  showTutorials = false;
  showRegularHeader = true;
  headerColor = false;
  showStickyBar = false;
  isUIBrowser = false;
  signUrl = environment.WEB_URL;
  canShowStickyBottom = false;

  constructor(private primengConfig: PrimeNGConfig, public translate: TranslateService,private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,  public sharedService: SharedService,public router: Router,private el: ElementRef
    , public commonService : CommonService,@Inject(PLATFORM_ID) public platformId: Object,private renderer: Renderer2,
    public schema : SchemasService
    ) { 
    //translate.addLangs(['en', 'nl']);
    translate.setDefaultLang('en');
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      const scrollableContent = this.el.nativeElement.querySelector('.scrollableContent');
      this.renderer.setProperty(scrollableContent, 'scrollTop', 0);
      this.headerColor = false;
    });    
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.isSkillGroup = false;
    this.showProfile = false;

    if (isPlatformBrowser(this.platformId)) {
      this.isUIBrowser = true;
      // setting default jQuery error message
      $.validator.messages.required = 'Required!';
      this.sharedService.castValue.subscribe((value)=> {
        this.isOpened = value;
        this.showCountries = false;
        this.showProfile  = false;
        this.showSearch = false;
      })
      this.sharedService.selectedDeveloperWorkId.subscribe((value)=> {
        if( value !== null && value !== '' ){
        this.selectedDeveloperWorkId = value;
        this.showProfile = true;   
        }
      })
      this.sharedService.showSearch.subscribe((value)=> {
        if( value !== null && value !== undefined ){
        this.showSearch = value;
        }
      })

      this.sharedService.showCountries.subscribe((value)=>{
        if( value !== null && value !== undefined ){
          this.showCountries = value;
        }
      })

    }
    this.isBraveBrowser().then((isBrave) => {
      if (isBrave) {
        console.log("Running in the Brave browser");
        this.isNotBeaveBrowser = false;
      } else {
        console.log("Not running in the Brave browser");
        this.isNotBeaveBrowser = true;
      }
    });
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  openHireContactorForm(event) {
    this.selectedSkills = event;
    this.isSkillGroup = true;
  }

  openSkillGroupForm(event) {
    this.selectedSkills = event;
  }


  isBraveBrowser(): Promise<boolean> {
    return new Promise((resolve) => {
      if ((navigator as any).brave) {
        (navigator as any).brave.isBrave().then((response: boolean) => {
          resolve(response);
        });
      } else {
        resolve(false);
      }
    });
  }

  @ViewChild('scrollableContent') scrollableContent: ElementRef;

  ngAfterViewInit(): void {
      if(isPlatformBrowser(this.platformId)){
              if (this.scrollableContent) {
                this.addScrollListener();
              }
      }
  }

  private addScrollListener(): void {
    this.renderer.listen(this.scrollableContent.nativeElement, 'scroll', () => {
      this.handleScroll();
    });
  }

  private handleScroll(): void {
    // Get the scroll position 
    const scrollTop = this.scrollableContent.nativeElement.scrollTop;
    if(isPlatformBrowser(this.platformId)){
      this.sharedService.sendScrollEvt(scrollTop);
    }

    if (scrollTop > 60 || this.router.url.includes('/user-agreement') || this.router.url.includes('/privacy-policy') || (this.router.url == '/en') || this.router.url.includes('/responsible-use-policy')) {
      this.headerColor = true;
    } else {
      this.headerColor = false;
    }

    this.canShowStickyBottom = this.router.url == '/en';

    // Logic for showing/hiding the sticky bar
    if (scrollTop > 60 && this.canShowStickyBottom) {
      this.showStickyBar = true;
    } else {
      this.showStickyBar = false;
    }
  }

  public canShowHeaderFooter(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return !this.commonService.isViewTemplate;
    }
  }
}
