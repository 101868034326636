import {APP_ID, CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule, PLATFORM_ID} from '@angular/core';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {isPlatformBrowser} from '@angular/common';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import {NavbarComponent} from './navbar/navbar.component';
// import {FooterComponent} from './footer/footer.component';
import {SubscribeNowComponent} from './subscribe-now/subscribe-now.component';
import {ThankyouModalComponent} from './thankyou-modal/thankyou-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { CookiesConsentComponent } from './cookies-consent/cookies-consent.component';
import { AmyChatBotComponent } from './amy-chat-bot/amy-chat-bot.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MatDialogModule } from '@angular/material/dialog';
import { Box, Briefcase, CalendarCheck, ChartLine, ChartNoAxesColumnIncreasing, CircleHelp, Clock, Earth, FileText, FileUp, GraduationCap, LayoutPanelTop, Leaf, ListTodo, LucideAngularModule, MessageCircle, Monitor, Music4, Palette, Pencil, PenLine, Ruler, Scale, Search, SquarePlay, Stethoscope, Store, Target, Truck, Umbrella, UserCheck, UserPlus, Users, UsersRound, Video } from 'lucide-angular';
import { Home, Settings, User, AlertCircle, ChevronDown } from 'lucide-angular';

// Components
import { HeaderComponent } from "./components/header/header.component"
import { FooterComponent } from "./components/footer/footer.component"

// Use an object where icon names are keys and icon components are values
const icons = {
  Home,
  Search,
  Settings,
  User,
  AlertCircle,
  ChevronDown,
  Box,
  UsersRound,
  ChartNoAxesColumnIncreasing,
  Users,
  Target,
  CalendarCheck,
  Clock,
  ChartLine,
  Store,
  LayoutPanelTop,
  MessageCircle,
  UserPlus,
  Video,
  FileText,
  FileUp,
  Monitor,
  Ruler,
  Stethoscope,
  Palette,
  Earth,
  PenLine,
  Music4,
  Briefcase,
  Scale,
  Truck,
  Umbrella,
  Leaf,
  GraduationCap,
  CircleHelp,
  Pencil,
  SquarePlay,
  UserCheck,
  ListTodo
  // Continue adding icons as needed
};


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.locales}assets/i18n/`, '.json');
}

/*export function createTranslateLoader(platformId: any, http: HttpClient): TranslateLoader {
  const browserLoader = new TranslateHttpLoader(http, './assets/locales/', '.json');

  return new UniversalTranslateLoader(platformId, browserLoader, 'browser/assets/locales', '.json');
}*/

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain
  },
  position: "bottom-right",
  theme: "classic",
  palette: {
    popup: {
      background: "#000000",
      text: "#ffffff",
      link: "#ffffff"
    },
    button: {
      background: "#f1d600",
      text: "#000000",
      border: "transparent"
    }
  },
  type: "info",
  content: {
    message: "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Got it!",
    deny: "Refuse cookies",
    link: "Privacy policy",
    href: environment.WEB_URL + "en/privacy-policy",
    policy: "Cookie Policy"
  }
};
@NgModule({
  imports: [
    BrowserModule.withServerTransition({appId: 'aumnics-ai-website'}),
    LucideAngularModule.pick(icons),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatDialogModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [/* PLATFORM_ID, */HttpClient]
      }
    }),    
    BrowserAnimationsModule,
    DynamicDialogModule,
  ],
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    AmyChatBotComponent,
    SubscribeNowComponent,
    ThankyouModalComponent,
    CookiesConsentComponent,  
    HeaderComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
    console.log('locales', environment.locales);
  }
}
