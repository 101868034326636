import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../../app/core/services/common.service';
import { SharedService } from '../../../app/core/services/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  mobileMenuOpen = false
  activeCategory = "Software & IT"
  moreDropdownOpen = false
  visibleCategories: string[] = []
  moreMenuCategories: string[] = []
  featuresMenuOpen = false
  learnMenuOpen = false
  activeMobileSubmenu: string | null = null;
  signUrl: any;
  languageCode: string = "en";
  homeBlog: any;
  appsUrl = environment.CMS_APPS_URL;
  web_url :string =environment.CMS_APPS_URL
  public websiteUrl = environment.WEBSITE_URL;
  appName = 'amy';

  @ViewChild("menuContainer") menuContainerRef!: ElementRef
  @ViewChild("moreButton") moreButtonRef!: ElementRef
  @ViewChild("featuresButton") featuresButtonRef!: ElementRef
  @ViewChild("learnButton") learnButtonRef!: ElementRef
  @ViewChild('categoriesContainer') categoriesContainer!: ElementRef;


  // Main navigation items
  mainNavItems = [
    { name: "header.nav.features", href: "/en", hasDropdown: true, id: "features" },
    { name: "header.nav.aboutUs", href: "/en/about-us", hasDropdown: false, id: "about" },
    { name: "header.nav.pricing", href: "/en/pricing", hasDropdown: false, id: "pricing" },
    { name: "header.nav.compare", href: "/en/compare-amy-category", hasDropdown: false, id: "compare" },
    { name: "header.nav.learn", href: "/en", hasDropdown: true, id: "learn" },
    { name: "header.nav.hire", href: "/en/find-agencies", hasDropdown: false, id: "hire" },
  ]

  // Main industry categories for the secondary navigation
  industryCategories = [
    "Software & IT",
    "AI Services",
    "Digital Marketing",
    "Civil Engineering & Architecture", 
    "Design & Creative",
    "Admin & Customer Support",
    "Writing & Translation",
    "Finance & Accounting",
    "Healthcare & Medical",
    "Education & Training"
  ]

  // Features mega menu data
  featuresMenu = {
    askAmy: {
      title: 'ASK AMY',
      features: [
        { 
          icon: 'pi pi-search', 
          title: 'Find answers', 
          description: 'Get instant solutions to your queries',
          href: '/en/find-answers'
        },
        { 
          icon: 'pi pi-cog', 
          title: 'Automate tasks', 
          description: 'Simplify and streamline your workflows',
          href: '/en/automate-tasks'
        },
        { 
          icon: 'pi pi-user', 
          title: 'Hire contract talent', 
          description: 'Quickly connect with skilled professionals',
          href: '/en/hire-contract-talent'
        },
        { 
          icon: 'pi pi-chart-line', 
          title: 'Find insights', 
          description: 'Access actionable data and analytics',
          href: '/en/find-insights'
        }
      ]
    },
    projectManagement: {
      title: 'PROJECT MANAGEMENT',
      features: [
        { 
          icon: 'pi pi-users', 
          title: 'Plan collaboratively', 
          description: 'Work together to create effective plans',
          href: '/en/plan-collaborately'
        },
        { 
          icon: 'pi pi-flag', 
          title: 'Set goals and targets', 
          description: 'Define objectives and measure progress',
          href: '/en/set-goals-targets'
        },
        { 
          icon: 'pi pi-calendar', 
          title: 'Sprint planning', 
          description: 'Track performance with regular surveys',
          href: '/en/surveys'
        },
        { 
          icon: 'pi pi-list', 
          title: 'Tasks, subtasks, bugs', 
          description: 'Organize and manage project details effortlessly',
          href: '/en/tasks-subtasks-bugs'
        },
        { 
          icon: 'pi pi-clock', 
          title: 'Time sheets', 
          description: 'Track and log work hours seamlessly',
          href: '/en/time-sheets'
        }
      ]
    },
    connectedEnterprise: {
      title: 'CONNECTED ENTERPRISE',
      features: [
        { 
          icon: 'pi pi-file', 
          title: 'Industry templates', 
          description: 'Leverage ready-to-use templates',
          href: '/en/industry-wise-templates'
        },
        { 
          icon: 'pi pi-dollar', 
          title: 'Compare prices', 
          description: 'Evaluate costs and choose partners',
          href: '/en/compare-prices-of-agencies'
        },
        { 
          icon: 'pi pi-shopping-cart', 
          title: 'Vertical marketplace', 
          description: 'Access specialized industry tools',
          href: '/en/experience-vertical-marketplace'
        },
        { 
          icon: 'pi pi-users', 
          title: 'Manage teams', 
          description: 'Coordinate all teams in one place',
          href: '/en/manage-in-house-teams'
        }
      ]
    },
    enterpriseCollaboration: {
      title: 'ENTERPRISE COLLABORATION',
      features: [
        { 
          icon: 'pi pi-comments', 
          title: 'One-to-one chat', 
          description: 'Communicate directly with team',
          href: '/en/collaboration/one-to-one-chat'
        },
        { 
          icon: 'pi pi-users', 
          title: 'Group chat', 
          description: 'Collaborate in team discussions',
          href: '/en/collaboration/group-chat'
        },
        { 
          icon: 'pi pi-video', 
          title: 'Video calls', 
          description: 'Conduct virtual meetings easily',
          href: '/en/collaboration/video-calls-sharing'
        },
        { 
          icon: 'pi pi-file', 
          title: 'File sharing', 
          description: 'Exchange documents securely',
          href: '/en/collaboration/file-sharing'
        }
      ]
    }
  }

  // Learn mega menu data
  learnMenu = {
    gettingStarted: {
      title: 'LEARN',
      features: [
        { 
          icon: 'pi pi-question-circle', 
          title: 'Help Center', 
          description: 'Get expert guidance and technical support to resolve your queries quickly and efficiently',
          href: this.appsUrl + 'en/faq'
        },
        { 
          icon: 'pi pi-file', 
          title: 'Amy Blog', 
          description: 'Explore insights, trends, and best practices to enhance your collaboration and productivity strategies',
          href: this.appsUrl + 'en/blogs'
        },
        { 
          icon: 'pi pi-book', 
          title: 'Collaboration School', 
          description: 'Learn new skills and best practices through engaging tutorials and comprehensive courses',
          href: this.websiteUrl + this.languageCode + '/collaboration-school'
        },
        { 
          icon: 'pi pi-volume-up', 
          title: 'Productivity Revolution Podcast', 
          description: 'Tune in for expert discussions and ideas to revolutionize workplace productivity',
          href: this.websiteUrl + this.languageCode + '/podcasts'
        }
      ]
    }
  }

  constructor(public router: Router,
    public sharedService: SharedService,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    
    this.commonService.loadUrlGuideEvt().subscribe((res: any) => {
      if(res == 'amp') {
        this.appName = 'amp/packages';
        this.commonService.SignupUrl = 'get-started/u/setup-profile?returnUrl='+this.appName;
      }
    })
  }

  scrollCategories(direction: 'left' | 'right') {
    const container = this.categoriesContainer.nativeElement;
    const scrollAmount = (container.scrollWidth / this.industryCategories.length) * 2;

    if (direction === 'left') {
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }

  ngAfterViewInit(): void {
    this.calculateVisibleCategories()
  }

  // Calculate which categories should be visible and which should be in the More dropdown
  calculateVisibleCategories(): void {
    if (!this.menuContainerRef || typeof window === "undefined") return

    const containerWidth = this.menuContainerRef.nativeElement.offsetWidth
    const moreButtonWidth = 120 // Width of the "More" button including padding and margin
    const buffer = 48 // Buffer space for margins and padding
    const itemWidth = 180 // Width of each category button including padding and margin

    // Calculate how many items can fit, ensuring at least 3 categories go to More menu
    const availableWidth = containerWidth - moreButtonWidth - buffer
    const maxVisibleItems = Math.floor(availableWidth / itemWidth)
    const minMoreItems = 3 // Minimum number of items in More dropdown
    const visibleCount = Math.max(0, Math.min(maxVisibleItems, this.industryCategories.length - minMoreItems))

    // Update visible and more menu categories
    this.visibleCategories = this.industryCategories.slice(0, visibleCount)
    this.moreMenuCategories = this.industryCategories.slice(visibleCount)
  }

  @HostListener("window:resize")
  onResize(): void {
    this.calculateVisibleCategories()
  }

  @HostListener("document:mousedown", ["$event"])
  onClickOutside(event: MouseEvent): void {
    // Close more dropdown if clicked outside
    if (this.moreButtonRef && !this.moreButtonRef.nativeElement.contains(event.target)) {
      this.moreDropdownOpen = false
    }
    
    // Close features menu if clicked outside
    if (this.featuresButtonRef && 
        !this.featuresButtonRef.nativeElement.contains(event.target) && 
        !document.getElementById('features-mega-menu')?.contains(event.target as Node)) {
      this.featuresMenuOpen = false
    }

    // Close learn menu if clicked outside
    if (this.learnButtonRef && 
        !this.learnButtonRef.nativeElement.contains(event.target) && 
        !document.getElementById('learn-mega-menu')?.contains(event.target as Node)) {
      this.learnMenuOpen = false
    }
  }

  toggleMobileMenu(): void {
    this.mobileMenuOpen = !this.mobileMenuOpen
    // Reset active submenu when closing mobile menu
    if (!this.mobileMenuOpen) {
      this.activeMobileSubmenu = null
    }
  }

  setActiveCategory(category: string): void {
    this.activeCategory = category
    this.moreDropdownOpen = false
  }

  toggleMoreDropdown(): void {
    this.moreDropdownOpen = !this.moreDropdownOpen
  }

  toggleFeaturesMenu(): void {
    this.featuresMenuOpen = !this.featuresMenuOpen
    // Close other mega menus
    if (this.featuresMenuOpen) {
      this.learnMenuOpen = false
    }
  }

  toggleLearnMenu(): void {
    this.learnMenuOpen = !this.learnMenuOpen
    // Close other mega menus
    if (this.learnMenuOpen) {
      this.featuresMenuOpen = false
    }
  }

  closeFeaturesMenu(): void {
    this.featuresMenuOpen = false
  }

  closeLearnMenu(): void {
    this.learnMenuOpen = false
  }

  toggleMobileSubmenu(menuId: string): void {
    this.activeMobileSubmenu = this.activeMobileSubmenu === menuId ? null : menuId
  }

  // Helper method to check if a mobile submenu is active
  isMobileSubmenuActive(menuId: string): boolean {
    return this.activeMobileSubmenu === menuId
  }
}