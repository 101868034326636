import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Location} from '@angular/common';

import {LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings, ManualParserLoader} from '@gilsdav/ngx-translate-router';
import {TranslateService} from '@ngx-translate/core';

/* export function createTranslateLoader(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, settings, http)
} */

export function createTranslateLoader(translate: TranslateService, location: Location, settings: LocalizeRouterSettings) {
  return new ManualParserLoader(translate, location, settings, ['en', 'fr'], 'ROUTES.');
}

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../app/home/home.module')
      .then(mod => mod.HomeModule)
  },
  {
    path: 'connect',
    loadChildren: () => import('../app/connect/connect.module')
      .then(mod => mod.ConnectModule)
  },

  {
    path: 'cms',
    loadChildren: () => import('../app/cms/cms.module')
      .then(mod => mod.CmsModule)
  },

  {
    path: 'book-demo',
    loadChildren: () => import('../app/booking-demo/booking-demo.module')
      .then(mod => mod.BookingDemoModule)
  },

  {
    path: 'about-us',
    loadChildren: () => import('../app/about-amy-connect/about-amy-connect.module')
      .then(mod => mod.AboutAmyConnectModule)
  },
  {
    path: 'amy-service-provider',
    loadChildren: () => import('../app/service-provider/service-provider.module')
      .then(mod => mod.ServiceProviderModule)
  },
  {
    path: 'features',
    loadChildren: () => import('../app/amy-features/amy-features.module')
      .then(mod => mod.AmyFeaturesModule)
  },

  {
    path: 'features',
    loadChildren: () => import('../app/amy-features/amy-features.module')
      .then(mod => mod.AmyFeaturesModule)
  },
  {
    path: 'pricing',
    loadChildren: () => import('../app/shared/pricing/pricing.module')
      .then(mod => mod.PricingModule)
  },
  {
    path: 'why-amy',
    loadChildren: () => import('../app/why-amy-page/why-amy-page.module')
      .then(mod => mod.WhyAmyPageModule)
  },
  {
    path: 'clockit',
    loadChildren: () => import('../app/clock-it/clock-it.module')
      .then(mod => mod.ClockItModule)
  },
  
  {
    path: 'contact-us',
    loadChildren: () => import('../app/contact-us-connect/contact-us-connect.module')
      .then(mod => mod.ContactUsConnectModule)
  },

  {
    path: 'responsible-use-policy',
    loadChildren: () => import('../app/user-policy-common/user-policy-common.module')
      .then(mod => mod.UserPolicyCommonModule)
  },

  {
    path: 'user-agreement',
    loadChildren: () => import('../app/user-agreement-common/user-agreement-common.module')
      .then(mod => mod.UserAgreementCommonModule)
  },
  {
    path: 'tutorials',
    loadChildren: () => import('../app/tutorials-common/tutorials-common.module')
      .then(mod => mod.TutorialsCommonModule)
  },
  {
    path: 'people-onbording',
    loadChildren: () => import('../app/senior-people-onbording/senior-people-onbording.module')
      .then(mod => mod.SeniorPeopleOnbordingModule)
  },
  {
    path: 'senior-proffesionals',
    loadChildren: () => import('../app/senior-people-page/senior-people-page.module')
      .then(mod => mod.SeniorPeoplePageModule)
  },
  {
    path: 'productivity-features',
    loadChildren: () => import('../app/productivity-features/productivity-features.module')
      .then(mod => mod.ProductivityFeaturesModule)
  },
  {
    path: 'industries',
    loadChildren: () => import('../app/industries/industries.module')
      .then(mod => mod.IndustriesModule)
  },
  {
    path: 'compare-amy-category',
    loadChildren: () => import('../app/compare-features/compare-features.module')
      .then(mod => mod.CompareFeaturesModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('../app/privacy-policy/privacy-policy.module')
      .then(mod => mod.PrivacyPolicyModule)
  },
  {
    path: 'task-manager',
    loadChildren: () => import('../app/task-manager/task-manager.module')
      .then(mod => mod.TaskManagerModule)
  },
  {
    path: 'find-answers',
    loadChildren: () => import('../app/find-answers/find-answers.module')
      .then(mod => mod.FindAnswersModule)
  },
  {
    path: 'automate-tasks',
    loadChildren: () => import('../app/automate-tasks/automate-tasks.module')
      .then(mod => mod.AutomateTasksModule)
  },
  {
    path: 'hire-contract-talent',
    loadChildren: () => import('../app/contract-talent/contract-talent.module')
      .then(mod => mod.ContractTalentModule)
  },
  {
    path: 'find-insights',
    loadChildren: () => import('../app/find-insights/find-insights.module')
      .then(mod => mod.FindInsightsModule)
  },
  {
    path: 'plan-collaborately',
    loadChildren: () => import('../app/plan-collaborately/plan-collaborately.module')
      .then(mod => mod.PlanCollaboratelyModule)
  },
  {
    path: 'set-goals-targets',
    loadChildren: () => import('../app/set-goals-targets/set-goals-targets.module')
      .then(mod => mod.SetGoalsTargetsModule)
  },
  {
    path: 'surveys',
    loadChildren: () => import('../app/surveys/surveys.module')
      .then(mod => mod.SurveysModule)
  },
  {
    path: 'tasks-subtasks-bugs',
    loadChildren: () => import('../app/tasks-subtasks/tasks-subtasks.module')
      .then(mod => mod.TasksSubtasksModule)
  },
  {
    path: 'time-sheets',
    loadChildren: () => import('../app/time-sheets/time-sheets.module')
      .then(mod => mod.TimeSheetsModule)
  },
  {
    path: 'industry-wise-templates',
    loadChildren: () => import('../app/industry-wise-templates/industry-wise-templates.module')
      .then(mod => mod.IndustryWiseTemplatesModule)
  },
  {
    path: 'compare-prices-of-agencies',
    loadChildren: () => import('../app/compare-price/compare-price.module')
      .then(mod => mod.ComparePriceModule)
  },
  {
    path: 'experience-vertical-marketplace',
    loadChildren: () => import('../app/experience-markateplace/experience-markateplace.module')
      .then(mod => mod.ExperienceMarkateplaceModule)
  },
  {
    path: 'collaboration',
    loadChildren: () => import('../app/collaboration-features/collaboration-features.module')
      .then(mod => mod.CollaborationFeaturesModule)
  },
  {
    path: 'manage-in-house-teams',
    loadChildren: () => import('../app/manage-inhouse-teams/manage-inhouse-teams.module')
      .then(mod => mod.ManageInhouseTeamsModule)
  },
  {
    path: 'apps',
    loadChildren: () => import('../app/feature-apps/feature-apps.module')
      .then(mod => mod.FeatureAppsModule)
  },
  {
    path: 'templates',
    loadChildren: () => import('../app/templates/templates.module')
      .then(mod => mod.TemplatesModule)
  },
  {
    path: 'find-agencies',
    loadChildren: () => import('../app/client-page/client-page.module')
      .then(mod => mod.ClientPageModule)
  },
  {
    path: 'trusted-service-provider-partners',
    loadChildren: () => import('../app/trusted-service-provider-partners/trusted-service-provider-partners.module')
      .then(mod => mod.TrustedServiceProviderPartnersModule)
  },
  {
    path: 'podcasts',
    loadChildren: () => import('../app/podcasts/podcasts.module')
      .then(mod => mod.PodcastsModule)
  },
  {
    path: 'collaboration-school',
    loadChildren: () => import('../app/collaboration-school/collaboration-school.module')
      .then(mod => mod.CollaborationSchoolModule)
  },
  {
    path: '**',
    loadChildren: () => import('../app/home/home.module')
      .then(mod => mod.HomeModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {initialNavigation: 'disabled', relativeLinkResolution: 'legacy'}),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (createTranslateLoader),
        deps: [TranslateService, Location, LocalizeRouterSettings/*, HttpClient*/]
      },
      initialNavigation: true
    })
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class AppRoutingModule {
}
