<header class="fixed top-0 left-0 right-0 z-50 bg-white/95 backdrop-blur-sm shadow-sm">
  <!-- Main navigation bar -->
  <div class="w-full">
    <div class="flex items-center justify-between h-16 px-4 md:px-6 lg:px-8 max-w-full mx-auto">
      <!-- Logo -->
      <div class="flex-shrink-0 mr-4">
        <a href="/en" class="flex items-center gap-2">
          <img src="assets/icons/Homepage/amy-logo.png" alt="{{ 'header.logoAlt' | translate }}" class="w-auto h-8">
        </a>
      </div>

      <!-- Primary Navigation - Desktop -->
      <nav class="hidden md:flex items-center gap-4 flex-grow">
        <div *ngFor="let item of mainNavItems; let i = index" class="relative group">
          <a 
            *ngIf="!item.hasDropdown"
            [href]="item.href" 
            class="flex items-center gap-1 text-slate-700 hover:text-indigo-600 text-sm font-medium py-1 px-2 rounded-md transition-colors whitespace-nowrap">
            {{ item.name | translate }}
          </a>
          
          <!-- Features button with toggle -->
          <button 
            *ngIf="item.id === 'features'"
            #featuresButton
            (click)="toggleFeaturesMenu()"
            class="flex items-center gap-1 text-slate-700 hover:text-indigo-600 text-sm font-medium py-1 px-2 rounded-md transition-colors whitespace-nowrap">
            {{ item.name | translate }}
            <i class="pi pi-angle-down h-4 w-4 opacity-70" [class.transform]="featuresMenuOpen" [class.rotate-180]="featuresMenuOpen"></i>
          </button>

          <!-- Learn button with toggle -->
          <button 
            *ngIf="item.id === 'learn'"
            #learnButton
            (click)="toggleLearnMenu()"
            class="flex items-center gap-1 text-slate-700 hover:text-indigo-600 text-sm font-medium py-1 px-2 rounded-md transition-colors whitespace-nowrap">
            {{ item.name | translate }}
            <i class="pi pi-angle-down h-4 w-4 opacity-70" [class.transform]="learnMenuOpen" [class.rotate-180]="learnMenuOpen"></i>
          </button>
        </div>
      </nav>

      <!-- Right side: Auth buttons, and Mobile menu toggle -->
      <div class="flex items-center gap-4">
        <!-- Auth buttons - Desktop -->
        <div class="hidden md:flex items-center gap-3">
          <a [href]="signUrl+'sign-in?returnUrl='+appName" class="text-slate-700 hover:text-indigo-600 text-sm font-medium transition-colors">
            {{ 'header.auth.login' | translate }}
          </a>
          <a [href]="signUrl+'get-started/u/setup-profile?returnUrl='+appName" class="bg-amber-400 hover:bg-amber-500 text-slate-900 px-4 py-2 rounded-lg text-sm font-medium shadow-sm hover:shadow-md transition-all duration-300">
            {{ 'header.auth.signup' | translate }}
          </a>
        </div>

        <!-- Mobile menu toggle -->
        <button 
          class="md:hidden flex items-center justify-center w-10 h-10 text-slate-700 hover:text-indigo-600 transition-colors"
          (click)="toggleMobileMenu()">          
          <i *ngIf="mobileMenuOpen" class="pi pi-times h-6 w-6"></i>
          <i *ngIf="!mobileMenuOpen" class="pi pi-bars h-6 w-6"></i>          
        </button>
      </div>
    </div>
  </div>

  <!-- Mobile menu -->
  <div *ngIf="mobileMenuOpen" class="md:hidden fixed inset-0 bg-slate-900/20 backdrop-blur-sm z-40" (click)="toggleMobileMenu()">
    <div class="absolute right-0 top-0 h-screen w-full max-w-sm bg-white shadow-xl overflow-y-auto" (click)="$event.stopPropagation()">
      <div class="p-4 border-b border-slate-100 flex justify-between items-center">
        <h2 class="font-semibold text-slate-800">{{ 'header.mobileMenu.title' | translate }}</h2>
        <button (click)="toggleMobileMenu()" class="p-1 text-slate-500 hover:text-slate-700">
          <i class="pi pi-times h-5 w-5"></i>
        </button>
      </div>

      <div class="p-4">
        <!-- Main navigation items -->
        <div>
          <!-- Regular nav items -->
           <ng-container *ngFor="let item of mainNavItems">
            <a  [href]="item.href"
              class="block py-2 text-base font-medium text-slate-700 hover:text-indigo-600"
              *ngIf="!item.hasDropdown">
              {{ item.name | translate }}
            </a>
           </ng-container>
          

          <!-- Features with submenu -->
          <div *ngFor="let item of mainNavItems" class="border-b border-slate-100 last:border-b-0">
            <div 
              *ngIf="item.id === 'features'"
              class="flex items-center justify-between py-2 text-base font-medium text-slate-700 hover:text-indigo-600 cursor-pointer"
              (click)="toggleMobileSubmenu('features')">
              <span>{{ item.name | translate }}</span>
              <i [class]="'pi ' + (isMobileSubmenuActive('features') ? 'pi-angle-up' : 'pi-angle-down')"></i>
            </div>

            <!-- Features submenu -->
            <div *ngIf="item.id === 'features' && isMobileSubmenuActive('features')" class="pl-4 pb-2">
              <!-- ASK AMY -->
              <div class="mb-4">
                <h4 class="text-xs font-semibold text-slate-500 py-2">{{ featuresMenu.askAmy.title }}</h4>
                <a 
                  *ngFor="let feature of featuresMenu.askAmy.features"
                  [routerLink]="feature.href"
                  class="flex items-center gap-2 py-2 text-sm text-slate-700 hover:text-indigo-600"
                  (click)="toggleMobileMenu()">
                  <i [class]="feature.icon + ' text-indigo-500'"></i>
                  <span>{{ feature.title }}</span>
                </a>
              </div>

              <!-- PROJECT MANAGEMENT -->
              <div class="mb-4">
                <h4 class="text-xs font-semibold text-slate-500 py-2">{{ featuresMenu.projectManagement.title }}</h4>
                <a 
                  *ngFor="let feature of featuresMenu.projectManagement.features"
                  [routerLink]="feature.href"
                  class="flex items-center gap-2 py-2 text-sm text-slate-700 hover:text-indigo-600"
                  (click)="toggleMobileMenu()">
                  <i [class]="feature.icon + ' text-pink-500'"></i>
                  <span>{{ feature.title }}</span>
                </a>
              </div>

              <!-- CONNECTED ENTERPRISE -->
              <div class="mb-4">
                <h4 class="text-xs font-semibold text-slate-500 py-2">{{ featuresMenu.connectedEnterprise.title }}</h4>
                <a 
                  *ngFor="let feature of featuresMenu.connectedEnterprise.features"
                  [routerLink]="feature.href"
                  class="flex items-center gap-2 py-2 text-sm text-slate-700 hover:text-indigo-600"
                  (click)="toggleMobileMenu()">
                  <i [class]="feature.icon + ' text-green-500'"></i>
                  <span>{{ feature.title }}</span>
                </a>
              </div>

              <!-- ENTERPRISE COLLABORATION -->
              <div class="mb-4">
                <h4 class="text-xs font-semibold text-slate-500 py-2">{{ featuresMenu.enterpriseCollaboration.title }}</h4>
                <a 
                  *ngFor="let feature of featuresMenu.enterpriseCollaboration.features"
                  [routerLink]="feature.href"
                  class="flex items-center gap-2 py-2 text-sm text-slate-700 hover:text-indigo-600"
                  (click)="toggleMobileMenu()">
                  <i [class]="feature.icon + ' text-purple-500'"></i>
                  <span>{{ feature.title }}</span>
                </a>
              </div>
            </div>

            <!-- Learn with submenu -->
            <div 
              *ngIf="item.id === 'learn'"
              class="flex items-center justify-between py-2 text-base font-medium text-slate-700 hover:text-indigo-600 cursor-pointer"
              (click)="toggleMobileSubmenu('learn')">
              <span>{{ item.name | translate }}</span>
              <i [class]="'pi ' + (isMobileSubmenuActive('learn') ? 'pi-angle-up' : 'pi-angle-down')"></i>
            </div>

            <!-- Learn submenu -->
            <div *ngIf="item.id === 'learn' && isMobileSubmenuActive('learn')" class="pl-4 pb-2">
              <!-- GETTING STARTED -->
              <div class="mb-4">
                <a 
                  *ngFor="let feature of learnMenu.gettingStarted.features; let idx = index"
                  [href]="feature.href" 
                  class="flex items-center gap-2 py-2 text-sm text-slate-700 hover:text-indigo-600"
                  (click)="toggleMobileMenu()">
                  <i [class]="feature.icon + ' text-blue-500'"></i>
                  <span>{{ feature.title }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Auth buttons -->
        <div class="border-t border-slate-100 pt-4">
          <div class="flex flex-col gap-3">
            <a [href]="signUrl+'sign-in?returnUrl='+appName" class="block w-full py-2 text-center text-indigo-600 border border-indigo-600 rounded-lg font-medium">
              {{ 'header.auth.login' | translate }}
            </a>
            <a [href]="signUrl+'get-started/u/setup-profile?returnUrl='+appName" class="block w-full py-2 text-center bg-amber-400 text-slate-900 rounded-lg font-medium shadow-sm hover:bg-amber-500">
              {{ 'header.auth.signup' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- Features Mega Menu -->
<div 
  *ngIf="featuresMenuOpen" 
  id="features-mega-menu"
  class="fixed top-16 left-0 right-0 bg-white shadow-lg border-t border-slate-100 z-40">
  <div class="max-w-7xl mx-auto py-3">
    <div class="row mx-0">          
      <div class="col-md-12">
        <div class="">
          <div class="row mx-0">
            <div class="collab-item-1 h-100">
              <div class="">
                <div class="card-body pl-0">
                  <h5 class="card-title mb-3">{{"askAmy.title" | translate}} {{"askAmy.title2" | translate}}</h5>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="search" class="nav-menu-icon icon-color-code-1"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/find-answers'" (click)="router.url != '/en/find-answers' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"askAmy.items.getAnswers" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"askAmy.items.getAnswersDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="box" class="nav-menu-icon icon-color-code-2"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/automate-tasks'" (click)="router.url != '/en/automate-tasks' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"askAmy.items.automateTasks" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"askAmy.items.automateTasksDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="users" class="nav-menu-icon icon-color-code-3"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/hire-contract-talent'" (click)="router.url != '/en/hire-contract-talent' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"askAmy.items.hireContractTalent" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"askAmy.items.hireContractTalentDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="chart-no-axes-column-increasing" class="nav-menu-icon icon-color-code-4"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/find-insights'" (click)="router.url != '/en/find-insights' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"askAmy.items.findInsights" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"askAmy.items.findInsightsDescription" | translate}}</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          
            <div class="collab-item-2 h-100">
              <div class="h-100">
                <div class="card-body">
                  <h5 class="card-title mb-3">{{"projectManagement.title" | translate}} {{"projectManagement.title2" | translate}}</h5>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="users-round" class="nav-menu-icon icon-color-code-5"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/plan-collaborately'" (click)="router.url != '/en/plan-collaborately' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"projectManagement.items.planCollaborately" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"projectManagement.items.planCollaboratelyDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="target" class="nav-menu-icon icon-color-code-6"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/set-goals-targets'" (click)="router.url != '/en/set-goals-targets' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"projectManagement.items.setGoals" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"projectManagement.items.setGoalsDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="calendar-check" class="nav-menu-icon icon-color-code-7"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/surveys'" (click)="router.url != '/en/surveys' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"projectManagement.items.sprintSurveys" | translate}}  </h4>
                        <p class="sub-heading-color f-12">{{"projectManagement.items.sprintSurveysDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="list-todo" class="nav-menu-icon icon-color-code-10"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/tasks-subtasks-bugs'" (click)="router.url != '/en/tasks-subtasks-bugs' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"projectManagement.items.tasksAndBugs" | translate}}  </h4>
                        <p class="sub-heading-color f-12">{{"projectManagement.items.tasksAndBugsDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="clock" class="nav-menu-icon icon-color-code-8"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/time-sheets'" (click)="router.url != '/en/time-sheets' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"projectManagement.items.timeSheets" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"projectManagement.items.timeSheetsDescription" | translate}}</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          
            <div class="collab-item-3 h-100">
              <div class="h-100">
                <div class="card-body">
                  <h5 class="card-title mb-3">{{"marketplaceServices.title" | translate}} {{"marketplaceServices.title2" | translate}}</h5>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="file-text" class="nav-menu-icon icon-color-code-9"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/industry-wise-templates'" (click)="router.url != '/en/industry-wise-templates' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"marketplaceServices.items.industryTemplates" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"marketplaceServices.items.industryTemplatesDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="chart-line" class="nav-menu-icon icon-color-code-10"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/compare-prices-of-agencies'" (click)="router.url != '/en/compare-prices-of-agencies' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"marketplaceServices.items.comparePrices" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"marketplaceServices.items.comparePricesDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="store" class="nav-menu-icon icon-color-code-11"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/experience-vertical-marketplace'" (click)="router.url != '/en/experience-vertical-marketplace' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"marketplaceServices.items.verticalMarketplace" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"marketplaceServices.items.verticalMarketplaceDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="layout-panel-top" class="nav-menu-icon icon-color-code-12"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/manage-in-house-teams'" (click)="router.url != '/en/manage-in-house-teams' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"marketplaceServices.items.manageTeams" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"marketplaceServices.items.manageTeamsDescription" | translate}}</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          
            <div class="collab-item-4 h-100">
              <div>
                <div class="card-body">
                  <h5 class="card-title mb-3">{{"enterpriseCollaboration.title" | translate}} {{"enterpriseCollaboration.title2" | translate}}</h5>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="message-circle" class="nav-menu-icon icon-color-code-13"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/one-to-one-chat'" (click)="router.url != '/en/collaboration/one-to-one-chat' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"enterpriseCollaboration.items.oneToOneChat" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"enterpriseCollaboration.items.oneToOneChatDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="user-plus" class="nav-menu-icon icon-color-code-14"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/group-chat'" (click)="router.url != '/en/collaboration/group-chat' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"enterpriseCollaboration.items.groupChat" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"enterpriseCollaboration.items.groupChatDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="video" class="nav-menu-icon icon-color-code-15"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/video-calls-sharing'" (click)="router.url != '/en/collaboration/video-calls-sharing' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"enterpriseCollaboration.items.videoCalls" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"enterpriseCollaboration.items.videoCallsDescription" | translate}}</p>
                      </a>
                    </li>
                    <li class="list-group-item paragraph-global-text mb-0 d-flex">
                      <lucide-icon name="file-up" class="nav-menu-icon icon-color-code-16"></lucide-icon>
                      <a class="text-decoration-none" [routerLink]=" '/' + languageCode + '/collaboration/file-sharing'" (click)="router.url != '/en/collaboration/file-sharing' ? closeFeaturesMenu() : null;">
                        <h4 class="heading-four mb-0">{{"enterpriseCollaboration.items.fileSharing" | translate}}</h4>
                        <p class="sub-heading-color f-12">{{"enterpriseCollaboration.items.fileSharingDescription" | translate}}</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
        </div>                                          
      </div>
    </div>

    <div>
      <div class="highlighted-section-bg d-flex justify-content-between align-items-center mt-2" style="border-radius: 1rem; padding: 2rem !important;">
        <div>
          <h5 class="heading-two text-white" style="line-height: 1.1 !important;">{{"expandBusiness.title" | translate}}</h5>
          <p class="paragraph-global-text text-white mb-0 mw-800" style="line-height: 1.3 !important;">{{"expandBusiness.desc" | translate}}</p>
        </div>
        <a [routerLink]="['/', languageCode, 'book-demo']" target="_blank" (click)="closeFeaturesMenu('business')">
          <button class="showMore-btn-cancel white-btn fw-500 w-100 mt-4">{{"expandBusiness.btnServiceProvider" | translate}}</button>
        </a>
      </div>
      
    </div>
  </div>
</div>

<!-- Learn Mega Menu -->
<div 
  *ngIf="learnMenuOpen" 
  id="learn-mega-menu"
  class="fixed top-16 left-0 right-0 bg-white shadow-lg border-t border-slate-100 z-40">
  <div class="max-w-7xl mx-auto py-3">
    <div class="row mx-0 nav-bar-padding"> 
      <div class="col-md-6 px-0">
        <div class="row">
          <div class="col-md-6 mb-2">
            <li class="list-group-item paragraph-global-text mb-0 learn-nav-card">                      
              <a class="text-decoration-none p-4 card card-hover-effect" [href]=" appsUrl + 'en/faq'" (click)="router.url != '/en/find-answers' ? closeLearnMenu() : null;">
                <div class="learn-menu-icon lmi-1 mb-3">
                  <lucide-icon name="circle-help"></lucide-icon>
                </div>
                <h4 class="heading-four mb-0">
                  {{"helpCentre.title" | translate}} {{"helpCentre.title2" | translate}} 
                  <!-- <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i>  -->
                </h4>
                <p class="paragraph-global-text mb-0">{{"helpCentre.description" | translate}}</p>
              </a>
            </li>
          </div>
          <div class="col-md-6 mb-2">
            <li class="list-group-item paragraph-global-text mb-0 learn-nav-card">                      
              <a class="text-decoration-none p-4 card card-hover-effect" [href]=" appsUrl + 'en/blogs'" (click)="router.url != '/en/find-answers' ? closeLearnMenu() : null;">
                <div class="learn-menu-icon lmi-2 mb-3">
                  <lucide-icon name="pencil"></lucide-icon>
                </div>
                <h4 class="heading-four mb-0">
                  {{"amyBlog.title" | translate}} {{"amyBlog.title2" | translate}}
                  <!-- <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i>  -->
                </h4>
                <p class="paragraph-global-text mb-0">{{"amyBlog.description" | translate}}</p>
              </a>
            </li>
          </div>
          <div class="col-md-6 mb-3">
            <li class="list-group-item paragraph-global-text mb-0 learn-nav-card">                      
              <a class="text-decoration-none p-4 card card-hover-effect" [routerLink]="'/' + languageCode + '/collaboration-school'" (click)="router.url != '/en/collaboration-school' ? closeLearnMenu() : null;">
                <div class="learn-menu-icon lmi-3 mb-3">
                  <lucide-icon name="square-play"></lucide-icon>
                </div>
                <h4 class="heading-four mb-0">
                  {{"collaborationSchool.title" | translate}} {{"collaborationSchool.title2" | translate}}
                  <!-- <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i>  -->
                </h4>
                <p class="paragraph-global-text mb-0">{{"collaborationSchool.description" | translate}}</p>
              </a>
            </li>
          </div>
          <div class="col-md-6 mb-3">
            <li class="list-group-item paragraph-global-text mb-0 learn-nav-card">                     
              <a class="text-decoration-none card card-hover-effect p-4" [routerLink]="'/' + languageCode + '/podcasts'" (click)="router.url != '/en/podcasts' ? closeLearnMenu() : null;">
                <div class="learn-menu-icon lmi-4 mb-3">
                  <lucide-icon name="user-check"></lucide-icon>
                </div>
                <h4 class="heading-four mb-0" style="line-height: 130% !important;">
                  {{"productivityRevolutionPodcast.title" | translate}} {{"productivityRevolutionPodcast.title2" | translate}}
                  <!-- <i class="pi pi-arrow-up-right primary-colordsr f-12 ml-1"></i>  -->
                </h4>
                <p class="paragraph-global-text mb-0">{{"productivityRevolutionPodcast.description" | translate}}</p>
              </a>
            </li>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="learn highlighted-section-bg rounded-style ml-auto">                 
          <div>
            <div class="opt-img single">
              <div class="bg-content-section p-0 mb-3" style="aspect-ratio: 16/9;">
                <img class="w-100 h-100" src="../../../assets/icons/collaboration-features/copied-template.png" alt="{{'gettingStarted.title' | translate}}">
              </div>                      
            </div>
            <div>
              <h6 class="heading-three mb-3 pb-0 text-white border-none d-flex align-items-center"
              style="line-height: 130% !important;">
                {{"makingCollaborationEasy.title" | translate}} {{"makingCollaborationEasy.title2" | translate}} </h6>                      
              <p class="paragraph-global-text text-white mb-4" style="line-height: 1.3 !important;">{{"makingCollaborationEasy.description" | translate}}</p>
              <a [routerLink]="'/' + languageCode + '/templates'" (click)="router.url != '/en/templates' ? closeLearnMenu() : null;">
                <button class="showMore-btn-cancel white-btn fw-500 mt-4">View templates</button>
              </a>
            </div>
          </div>
        </div>             
      </div>
    </div>
  </div>
</div>

<!-- Backdrop for mega menus -->
<div 
  *ngIf="featuresMenuOpen || learnMenuOpen" 
  class="fixed inset-0 bg-black/20 backdrop-blur-sm z-30"
  (click)="featuresMenuOpen ? closeFeaturesMenu() : closeLearnMenu()">
</div>

<!-- Spacer to prevent content from hiding under fixed header -->
<div class="h-16"></div>