import { Component, OnInit } from "@angular/core"
import { SharedService } from "../../../app/core/services/shared.service"
import { environment } from "../../../environments/environment";
import { CommonService } from "../../../app/core/services/common.service";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SubscribeNowComponent } from "../../../app/subscribe-now/subscribe-now.component";
import { ThankyouModalComponent } from "../../../app/thankyou-modal/thankyou-modal.component";
import { MessageService } from 'primeng/api';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  providers: [MessageService],
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  languageCode = 'en';
  web_url :string =environment.CMS_APPS_URL
  emailValidations: FormGroup;
  submitted = false;
  appName = 'amy';

  constructor(
    public sharedService : SharedService,
    public commonService : CommonService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
       this.emailValidations = this.formBuilder.group({
            emailId: [
              '',
              [
                Validators.required,
                Validators.email,
                Validators.pattern(
                  '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'
                ),
              ],
            ],
          });
  }

   get f(): { [key: string]: AbstractControl } {
      return this.emailValidations.controls;
    }
  
    onSubmit() {
      if (this.emailValidations.invalid) return;
      const email = this.emailValidations.get('emailId').value;
      this.validateEmailDomain(email);
    }
  
    openMatDrawer() {
      this.sharedService.sendValue(true);
    }
  
    openDailog() {
      const dialogRef = this.dialog.open(SubscribeNowComponent, {
        width: '550px',
        height:'780px',
        panelClass: 'subscribe-modal',
        disableClose: true,
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          let formData = this.emailValidations.value;
          formData.name = res;
          this.commonService.saveSubscribeData(formData).subscribe({
            next: (res) => {
              //console.log(res);
              this.openThankYouDailog();
            },
            error: (error) => {
              this.showError(error?.error?.message.errorMessage);
            },
          });
        }
      });
    }
  
    validateEmailDomain(email) {
      this.commonService.validateEmailDomains(email).subscribe((res: any) => {
        if (res) {
          this.openDailog();
        } else {
          this.showError('Enter valid email address');
        }
      });
    }
    showError(message) {
      this.messageService.add({
        key: 'bottomLeft',
        severity: 'error',
        summary: 'Error',
        detail: message,
      });
    }
    openThankYouDailog() {
      const dialogRef = this.dialog.open(ThankyouModalComponent, {
        width: '550px',
        height:'700px',
        panelClass: 'subscribe-modal',
        disableClose: true,
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.emailValidations.reset();
      });
    }
}

