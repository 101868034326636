import { EventEmitter, Inject, Injectable, Output, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { map, Observable } from 'rxjs';
import { EndPoint } from '../enums/end-point.enum';
import { HttpRequestService } from './http-request.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SeoService } from './seo.service';

import * as CryptoJS from 'crypto-js';
import { StorageService } from './storage.service';
import { NavigationStart, Router } from '@angular/router';
import { Routes } from '../enums/routes.enum';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';


declare var $: any;
class LocalStorage implements Storage {
    [name: string]: any;
    readonly length: number;
    clear(): void {}
    getItem(key: string): string | null {return undefined;}
    key(index: number): string | null {return undefined;}
    removeItem(key: string): void {}
    setItem(key: string, value: string): void {}
  }

// declare var $: any;
// declare var AktaAccount: any;
@Injectable({
    providedIn: 'root',
})


export class CommonService {

    private secretKey: any;
    public showDeveloperProfile = false;
    public selectedDeveloperWorkId = null;
    private storage: Storage;
    public currentRoute : any;
    SignupUrl = 'get-started/u/setup-profile';
    @Output() changeUrl = new EventEmitter();
    // list of icons
  public fileFormatsList =
  [
    { format: 'pdf', icon: 'assets/icons/files/pdf.svg' },
    { format: 'jpeg, jpg', icon: 'assets/icons/files/jpeg.svg' },
    { format: 'png', icon: 'assets/icons/files/png.svg' },
    { format: 'bmp', icon: 'assets/icons/files/bmp.svg' },
    { format: 'tif', icon: 'assets/icons/aumnics_file_svgs/tiff.svg' },
    { format: 'gif', icon: 'assets/icons/files/gif.svg' },
    { format: 'ram', icon: 'assets/icons/files/ram.svg' },
    { format: 'doc', icon: 'assets/icons/files/doc.svg' },
    { format: 'xls', icon: 'assets/icons/files/xls.svg' },
    { format: 'docx', icon: 'assets/icons/files/docx.svg' },
    { format: 'xlsx', icon: 'assets/icons/files/xlsx.svg' },
    { format: 'ppt', icon: 'assets/icons/files/ppt.svg' },
    { format: 'pptx', icon: 'assets/icons/files/pptx.svg' },
    { format: 'odt', icon: 'assets/icons/aumnics_file_svgs/odt.svg' },
    { format: 'ods', icon: 'assets/icons/files/ods.svg' },
    { format: 'odp', icon: 'assets/icons/files/odp.svg' },
    { format: 'odg', icon: 'assets/icons/files/odg.svg' },
    { format: 'odc', icon: 'assets/icons/files/odc.svg' },
    { format: 'txt', icon: 'assets/icons/files/txt.svg' },
    { format: 'rtf', icon: 'assets/icons/files/rtf.svg' },
    { format: 'tsv', icon: 'assets/icons/files/tsv.svg' },
    { format: 'tav', icon: 'assets/icons/files/tav.svg' },
    { format: 'ai', icon: 'assets/icons/files/ai.svg' },
    { format: 'eps', icon: 'assets/icons/files/eps.svg' },
    { format: 'svg_icon', icon: 'assets/icons/files/svg_icon.svg' },
    { format: 'html', icon: 'assets/icons/aumnics_file_svgs/html.svg' },
    { format: 'css', icon: 'assets/icons/aumnics_file_svgs/css.svg' },
    { format: 'js', icon: 'assets/icons/aumnics_file_svgs/js.svg' },
    { format: 'php', icon: 'assets/icons/aumnics_file_svgs/php.svg' },
    { format: 'otf', icon: 'assets/icons/files/otf.svg' },
    { format: 'ttf', icon: 'assets/icons/files/ttf.svg' },
    { format: 'zip', icon: 'assets/icons/files/zip.svg' },
    { format: 'rar', icon: 'assets/icons/files/rar.svg' },
    { format: 'xml', icon: 'assets/icons/aumnics_file_svgs/xml.svg' },
    { format: 'swift', icon: 'assets/icons/aumnics_file_svgs/swift.svg' },
    { format: 'wav', icon: 'assets/icons/aumnics_file_svgs/wav.svg' },
    { format: 'wmv', icon: 'assets/icons/aumnics_file_svgs/wmv.svg' },
    { format: 'yaml', icon: 'assets/icons/aumnics_file_svgs/yaml.svg' },
    // { format: 'xls', icon: 'assets/icons/aumnics_file_svgs/xls.svg' },
    { format: 'vb', icon: 'assets/icons/aumnics_file_svgs/vb.svg' },
    { format: '7z', icon: 'assets/icons/aumnics_file_svgs/7z.svg' },
    { format: 'avi', icon: 'assets/icons/aumnics_file_svgs/avi.svg' },
    { format: 'c', icon: 'assets/icons/aumnics_file_svgs/c.svg' },
    { format: 'class', icon: 'assets/icons/aumnics_file_svgs/class.svg' },
    { format: 'csv', icon: 'assets/icons/aumnics_file_svgs/csv.svg' },
    { format: 'dll', icon: 'assets/icons/aumnics_file_svgs/dll.svg' },        
    { format: 'ico', icon: 'assets/icons/aumnics_file_svgs/ico.svg' },
    { format: 'jar', icon: 'assets/icons/aumnics_file_svgs/jar.svg' },
    { format: 'kml', icon: 'assets/icons/aumnics_file_svgs/kml.svg' },
    { format: 'json', icon: 'assets/icons/aumnics_file_svgs/json.svg' },
    { format: 'mov', icon: 'assets/icons/aumnics_file_svgs/mov.svg' },
    { format: 'odt', icon: 'assets/icons/aumnics_file_svgs/odt.svg' },
    { format: 'ogg', icon: 'assets/icons/aumnics_file_svgs/ogg.svg' }, 
    { format: 'py', icon: 'assets/icons/aumnics_file_svgs/py.svg' },
    { format: 'pl', icon: 'assets/icons/aumnics_file_svgs/pl.svg' },
    { format: 'sql', icon: 'assets/icons/aumnics_file_svgs/sql.svg' },
    { format: 'tar', icon: 'assets/icons/aumnics_file_svgs/tar.svg' },
    { format: 'mp3', icon: 'assets/icons/aumnics_file_svgs/mp3.svg' },
    { format: 'mp4', icon: 'assets/icons/aumnics_file_svgs/mp4.svg' },
    { format: 'mpeg', icon: 'assets/icons/aumnics_file_svgs/mpeg.svg' },
    { format: 'bat', icon: 'assets/icons/aumnics_file_svgs/bat.svg' },
    { format: 'cs', icon: 'assets/icons/aumnics_file_svgs/cs.svg' },
    { format: 'url', icon: 'assets/icons/aumnics_file_svgs/link-svg.svg' },
    { format: 'snippet', icon: 'assets/icons/aumnics_file_svgs/code-snippet.svg' },
  ];

// list of default icons
defaultFileFormatsList = [
  { format: 'text', icon: 'assets/icons/files/default_text_files.svg' },
  { format: 'data', icon: 'assets/icons/files/default_data_files.svg' },
  { format: 'audio', icon: 'assets/icons/files/default_audio_files.svg' },
  { format: 'video', icon: 'assets/icons/files/default_video_files.svg' },
  { format: 'web', icon: 'assets/icons/files/default_web_files.svg' },
  { format: 'font', icon: 'assets/icons/files/default_font_files.svg' },
  { format: 'image', icon: 'assets/icons/files/default_raster_img_files.svg' },
  { format: 'compressed', icon: 'assets/icons/files/default_compressed.svg' },
];

// mimetype list
fileTypeList = [
  { extension: 'JPEG ,JPG ,GIF ,PNG ,GIF ,TIFF ,PSD ,AI ,INDD ,RAW ,EPS , SVG, SVGZ, HEIC', type: 'image' },
  { extension: 'DOC, DOCX, XLS, XLSX, PPT, PPTX, TXT, RTF, TSV, TAB, ODT, ODS, ODP, ODG, ODC, PDF', type: 'text' },
  { extension: 'AIF, IFF, M3U, M4A ,MID ,MP3 ,MPA ,WAV ,WMA, Vorbis, AAC, mpeg', type: 'audio' },
  { extension: 'MPG, MP4, 3GPP, 3GP, MOV, AVI, WMV, FLV, WebM, Theora, ogg', type: 'video' },
  { extension: 'ZIP, RAR, BZ2, TAR, GZ, LZ, LZ4, RZ, SZ, XZ, 7Z, S7Z, B6Z', type: 'compressed' },
];
    //private storageService: { [key: string]: string } = {};
    constructor(private httpRequest: HttpRequestService, private title: Title,private http: HttpClient,
        private seo:SeoService , private storageService : StorageService, private route : Router) { 
        this.seo.setMetaData()
        this.secretKey = 'ThisIsAnSecreteKey';
        this.storage = new ServerStorage();

     // Check the current route on initial load
     this.currentRoute = this.route.url; // Get the initial route URL
     this.checkRoute(); // Check route and set `isViewTemplate`
     // Listen for route changes
     this.route.events.subscribe((event) => {
       if (event instanceof NavigationStart) {
         this.currentRoute = event.url;
         this.checkRoute(); // Check route when navigation starts
       }
     });
  }
  
  // template variables 
  public isViewTemplate = false;
  public ViewedTemplate : any;

  public youtubeChannelLink = 'https://www.youtube.com/@amyteam9594';

  /* when update any template please check 1. Navigation for that page, 2. view order in navbar menu */
  public templates = [
    {
        "pmTemplateId": 71,
        "templateUuid": "271f3f99-40c9-4c58-9a18-ba82f47311ae",
        "templateName": "Software and IT Services",
        "description": "[Description content]",
        "shortDescription": "Contract talent from agencies or outsource sprints to agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/cc5cf9357f364419d11c.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/9f139cde36ff3b54508c.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/software.svg",
        "iconClass" : "monitor",
        "industryCategoryId": 7,
        "industryCategory": "Software and IT Services",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=271f3f99-40c9-4c58-9a18-ba82f47311ae"
    },
    {
        "pmTemplateId": 58,
        "templateUuid": "4afc4001-4fc1-4410-b590-88f7812f7b5e",
        "templateName": "Civil Engineering & Architecture",
        "description": "[Description content]",
        "shortDescription": "Architects or outsource building plans to civil engineering agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/cbe50312798bf5bbf7da.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/ea5a193dc2cc43e4ff4f.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/civil.svg",
        "iconClass" : "ruler",
        "industryCategoryId": 55,
        "industryCategory": "Civil Engineering & Architecture",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=4afc4001-4fc1-4410-b590-88f7812f7b5e"
    },
    {
        "pmTemplateId": 64,
        "templateUuid": "7929e367-b279-4b08-9d6e-1a65b3486fa5",
        "templateName": "Healthcare Services",
        "description": "[Description content]",
        "shortDescription": "Virtual assistants or outsource medical services to healthcare agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/534cea9533a28d1b649b.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/130c313aa1705ebd5d85.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/helth-care.svg",
        "iconClass" : "stethoscope",
        "industryCategoryId": 82,
        "industryCategory": "Healthcare Services",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=7929e367-b279-4b08-9d6e-1a65b3486fa5"
    },
    {
        "pmTemplateId": 57,
        "templateUuid": "edabe8a2-acfa-4411-81ad-da56948ada41",
        "templateName": "Design",
        "description": "[Description content]",
        "shortDescription": "Designers or outsource creative projects to design agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/80b4d28ea98d1536e635.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/3fe91fa9c7c2fb168844.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/design.svg",
        "iconClass" : "palette",
        "industryCategoryId": 1067,
        "industryCategory": "Design",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=edabe8a2-acfa-4411-81ad-da56948ada41"
    },
    {
        "pmTemplateId": 56,
        "templateUuid": "6d0fb5b2-aa8c-4cee-95af-2921a10e6b00",
        "templateName": "Digital Marketing",
        "description": "[Description content]",
        "shortDescription": "Digital marketers or outsource campaigns to marketing agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/f36389cfa2598e49bfcf.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/da97dd9d37052a06fa13.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/digital-marketing.svg",
        "iconClass" : "earth",
        "industryCategoryId": 24,
        "industryCategory": "Digital Marketing",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=6d0fb5b2-aa8c-4cee-95af-2921a10e6b00"
    },
    {
        "pmTemplateId": 69,
        "templateUuid": "197d25a2-3527-4f0c-8e40-3de2f463ae7f",
        "templateName": "Writing & Editing",
        "description": "[Description content]",
        "shortDescription": "Writers or outsource content creation to writing agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/f66602224e5309477590.png",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/e9224145264f9118cfea.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/writing.svg",
        "iconClass" : "pen-line",
        "industryCategoryId": 178,
        "industryCategory": "Writing & Editing",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=197d25a2-3527-4f0c-8e40-3de2f463ae7f"
    },
    {
        "pmTemplateId": 68,
        "templateUuid": "c3151d93-ed00-424d-9efb-1c3e11021331",
        "templateName": "Media Production",
        "description": "[Description content]",
        "shortDescription": "Media professionals or outsource production projects to skilled agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/769b589b605aae5a5595.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/710b3f4b172b08c30097.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/mdeia.svg",
        "iconClass" : "video",
        "industryCategoryId": 177,
        "industryCategory": "Media Production",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=c3151d93-ed00-424d-9efb-1c3e11021331"
    },
    {
        "pmTemplateId": 60,
        "templateUuid": "66bc4f09-03a2-4043-bbfc-8c5871e024d0",
        "templateName": "Music, Audio and Voice",
        "description": "[Description content]",
        "shortDescription": "Voiceover artists or outsource audio production to experienced agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/1cb9a5eda0e554cf9eed.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/d289a67c9d6fda8a894e.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/music.svg",
        "iconClass" : "music-4",
        "industryCategoryId": 179,
        "industryCategory": "Music, Audio and Voice",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=66bc4f09-03a2-4043-bbfc-8c5871e024d0"
    },
    {
        "pmTemplateId": 70,
        "templateUuid": "87170def-4c23-4549-b979-a4e89f71ffe2",
        "templateName": "Consulting & Professional Services",
        "description": "[Description content]",
        "shortDescription": "Consultants or outsource strategic planning to professional agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/84af9ea2ff7684e44a6e.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/38eaaa01733db221d1d9.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/consulting.svg",
        "iconClass" : "briefcase",
        "industryCategoryId": 14,
        "industryCategory": "Consulting & Professional Services",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=87170def-4c23-4549-b979-a4e89f71ffe2"
    },
    {
        "pmTemplateId": 66,
        "templateUuid": "c37275a1-da23-49c1-9326-c072b775670f",
        "templateName": "Legal and Compliance Services",
        "description": "[Description content]",
        "shortDescription": "Legal experts or outsource compliance audits to trusted law firms.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/a859b3bcb5f1a960f181.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/722e6a26c9ed61915818.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/legal.svg",
        "iconClass" : "scale",
        "industryCategoryId": 29,
        "industryCategory": "Legal and Compliance Services",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=c37275a1-da23-49c1-9326-c072b775670f"
    },
    {
        "pmTemplateId": 63,
        "templateUuid": "b5d57b20-14fe-411f-abd3-179c1127510b",
        "templateName": "Logistics & Supply Chain",
        "description": "[Description content]",
        "shortDescription": "Logistics professionals or outsource supply chain management to efficient agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/0503efa3ca610b66a8cc.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/04f6047121f96400467e.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/logistics.svg",
        "iconClass" : "truck",
        "industryCategoryId": 90,
        "industryCategory": "Logistics & Supply Chain",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=b5d57b20-14fe-411f-abd3-179c1127510b"
    },
    {
        "pmTemplateId": 62,
        "templateUuid": "0b9fb673-4a4e-431d-a277-094895714992",
        "templateName": "Insurance",
        "description": "[Description content]",
        "shortDescription": "Insurance experts or outsource claim processing to specialized agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/4900a19b95f1346900e7.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/a7737e813734bf3fd57f.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/insurance.svg",
        "iconClass" : "umbrella",
        "industryCategoryId": 96,
        "industryCategory": "Insurance",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=0b9fb673-4a4e-431d-a277-094895714992"
    },
    {
        "pmTemplateId": 61,
        "templateUuid": "6ced5358-39e0-479b-a62e-db9b4440af1f",
        "templateName": "Environmental Services",
        "description": "[Description content]",
        "shortDescription": "Environmental consultants or outsource sustainability planning to expert agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/0f790bf7caed80d0d465.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/a07f0243575bafb59e69.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/environment.svg",
        "iconClass" : "leaf",
        "industryCategoryId": 124,
        "industryCategory": "Environmental Services",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=6ced5358-39e0-479b-a62e-db9b4440af1f"
    },
    {
        "pmTemplateId": 65,
        "templateUuid": "baa5eb76-bf4d-46e3-8241-d0a34b4441bc",
        "templateName": "Research and Analysis",
        "description": "[Description content]",
        "shortDescription": "Analysts or outsource market research to professional research agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/decd6c4993a0552aa4e7.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/20d9204622c8e3c025e3.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/research.svg",
        "iconClass" : "search",
        "industryCategoryId": 176,
        "industryCategory": "Research and Analysis",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=baa5eb76-bf4d-46e3-8241-d0a34b4441bc"
    },
    {
        "pmTemplateId": 59,
        "templateUuid": "55422095-5ab5-4257-b7bf-881abb9af111",
        "templateName": "Events Services",
        "description": "[Description content]",
        "shortDescription": "Event planners or outsource event management to experienced event agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/3af3632f0432359caa87.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/5bb248f26c170fa4dc6e.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/events.svg",
        "iconClass" : "calendar-check",
        "industryCategoryId": 239,
        "industryCategory": "Events Services",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=55422095-5ab5-4257-b7bf-881abb9af111"
    },
    {
        "pmTemplateId": 67,
        "templateUuid": "8082d790-7c5a-4659-a4f0-99db0a0f6c0f",
        "templateName": "Training and Educational Services",
        "description": "[Description content]",
        "shortDescription": "Trainers or outsource corporate training to specialized training agencies.",
        "imageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/29308eff8b4b86efaccb.jpeg",
        "thumbnailImageUrl": "https://aumnicsappdatastore.s3.ap-south-1.amazonaws.com/profilepictures_staging/56be3dec88de4aa87660.svg",
        "iconUrl": "../../assets/icons/nav-bar/Indutry-icons/training.svg",
        "iconClass" : "graduation-cap",
        "industryCategoryId": 1068,
        "industryCategory": "Training and Educational Services",
        "templateUrl": environment.WEBSITE_URL + "en/task-manager/templates/view?templateUuid=8082d790-7c5a-4659-a4f0-99db0a0f6c0f"
    }
]

coveredNewsChannels = [
    {
      id: "1",
      image: "../../assets/images/news-logos/news-newyork-times.png",
      thumbImage: "../../assets/images/news-logos/news-newyork-times.png", // Use the same image URL for thumbnails or provide a separate URL
      alt: "The New York Times",
      title: "The New York Times",
    },
    {
      id: "2",
      image: "../../assets/images/news-logos/news-news-today.png",
      thumbImage: "../../assets/images/news-logos/news-news-today.png",
      alt: "Newsday",
      title: "Newsday",
    },
    {
      id: "3",
      image: "../../assets/images/news-logos/new-usa-today.png",
      thumbImage: "../../assets/images/news-logos/new-usa-today.png",
      alt: "USA Today",
      title: "USA Today",
    },
    {
      id: "4",
      image: "../../assets/images/news-logos/news-cio-div.svg",
      thumbImage: "../../assets/images/news-logos/news-cio-div.svg",
      alt: "CIO",
      title: "CIO",
    },
    {
      id: "5",
      image: "../../assets/images/news-logos/news-washington-div.png",
      thumbImage: "../../assets/images/news-logos/news-washington-div.png",
      alt: "The Washington Post",
      title: "The Washington Post",
    },
    {
      id: "1",
      image: "../../assets/images/collaboration/news-covered/Associated_Press_logo.svg.png",
      thumbImage: "../../assets/images/collaboration/news-covered/Associated_Press_logo.svg.png", // Use the same image URL for thumbnails or provide a separate URL
      alt: "Associated_Press_logo",
      title: "Associated_Press_logo",
    },
    {
      id: "2",
      image: "../../assets/images/collaboration/news-covered/GlobeNewswire.webp",
      thumbImage: "../../assets/images/news-logos/news-news-today.png",
      alt: "GlobeNewswire",
      title: "GlobeNewswire",
    },
    {
      id: "3",
      image: "../../assets/images/collaboration/news-covered/new-jersey-business-journal.svg",
      thumbImage: "../../assets/images/news-logos/new-usa-today.png",
      alt: "new-jersey-business-journal",
      title: "new-jersey-business-journal",
    },
    {
      id: "2",
      image: "../../assets/images/collaboration/news-covered/PRN_Logo_Blue.png",
      thumbImage: "../../assets/images/news-logos/news-news-today.png",
      alt: "PRN",
      title: "PRN",
    },
    {
      id: "3",
      image: "../../assets/images/collaboration/news-covered/newswire.png",
      thumbImage: "../../assets/images/news-logos/new-usa-today.png",
      alt: "newswire",
      title: "newswire",
    }
  ];

  // sp modal variables 
  expertiseLevels = [
    { value: 211, label: 'Beginner' },
    { value: 212, label: 'Intermediate' },
    { value: 213, label: 'Expert' },
    { value: 214, label: 'Top talent' },
  ]
  developers: any = 10;
  selectedExpertise: any = { value: 212, label: 'Intermediate' };
  selectedExperience = 8;
  selectedLocation : any;
  averageTotalPrice:any = 3347;
  selectedCityDetails : any = {
    cityId : 1187,
    cityName : "Hyderabad"
  }
  selectedIndustry : any = {
    categoryId : 7,
    categoryName : "Software and IT Services"
  }

  // Function to check the route and set `isViewTemplate`
  checkRoute() {
    if (this.currentRoute.includes(Routes.TASK_MANAGER_TEMPLATE_VIEW) || this.currentRoute.includes(Routes.INVATLID_TEMPLATE)) {
      this.isViewTemplate = true;
    } else {
      this.isViewTemplate = false;
    }
  }

    //For encription
    encryptString(value : string) : string{
        console.log(value + ' encrypted ' + CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString())
        this.decryptString(CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString());
        return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
      }
    
      decryptString(textToDecrypt : string){
        console.log(textToDecrypt + ' Decrypted ' + CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8))
        return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
      }

    public getAllTimeZones(offset?: string): Observable<any> {
        if (!offset) {
            offset = this.getUserTzOffset();
        }
        return this.httpRequest.globalGetMethod(EndPoint.GET_ALL_TIMEZONES, { offset });
    }

    public getCities(searchKeyWord: string, pageNo: number = 0, countryCode: string = ''): Observable<any> {
        return this.httpRequest.globalGeoLocationGetMethod(EndPoint.SEARCH_CITIES, { searchKeyWord, pageNo, countryCode });
    }

    public getAllSkillGroups(getSkills: boolean = false) {
        return this.httpRequest.globalGetMethod(EndPoint.GET_ALL_SKILL_GROUPS, { getSkills });
    }

    public getAllIndustryTypes() {
      return this.httpRequest.globalGetMethod(EndPoint.GET_ALL_INDUSTRY_TYPES, { });
    }

    public getSkillGroupsOfActiveDevelopers(obj){
        return this.httpRequest.globalGetMethod(EndPoint.GET_SKILL_GROUPS_WITH_ACTIVE_DEVELOPERS, obj);
    }

    public getDemoSlots(obj) {
        return this.httpRequest.globalGetMethod(EndPoint.GET_DEMO_SLOTS, obj);
    }

    public saveConnectDemoBooking(obj) {
        return this.httpRequest.globalPostMethod(EndPoint.SAVE_CONNECT_DEMO_BOOKING, obj);
    }

    public validateEmailDomains(email): Observable<any> {
        return this.httpRequest.globalGetMethod(EndPoint.VALIDATE_EMAIL_DOMAINS, { email });
    }

    public getUserTzOffset() {
        const dateFromBrowser = new Date().toString().split(' ');
        const tzFromBrowser = dateFromBrowser[5];
        const hours = tzFromBrowser.substring(tzFromBrowser.length - 5, tzFromBrowser.length - 2);
        const minutes = tzFromBrowser.substring(tzFromBrowser.length - 2, tzFromBrowser.length);
        return hours + ':' + minutes;
    }

    public setTitle(newTitle: string) {
        this.title.setTitle('Amy | ' + newTitle);
    }

    public convertDateByTimeZone(date: Date, timeZone: string): Date {
        return new Date(new Date(date).toLocaleString("en-US", { timeZone }));
    }

    public showToaster(message, severity = 'success', summary = 'Success') {
        // this.messageService.add({ severity, summary, detail: message });
    }

    public getProjectContinuityResources() {
        return this.httpRequest.globalGetMethodWithoutParams(EndPoint.GET_PROJECT_CONTINUITITY)
    }

    public saveNewRequirement(obj) {
        return this.httpRequest.globalPostMethod(EndPoint.NEW_REQUIREMENT, obj);
    }


     public saveSubscribeData(data) {
        return this.httpRequest.saveSubscribe(EndPoint.Save_Subscriber_User,data);
    }

    public getHomeBlog() {
        const headers = new HttpHeaders().set('accessToken', environment.blogsAccessToken);
        const options = { headers: headers };

        return this.httpRequest.globalGetMethod(EndPoint.Get_Home_Blog, {},options);
      }

    public getAllCountryPhoneNumPrefix(): Observable<any> {
        const url = EndPoint.GET_COUNTRY_PHONE_NUMBER_PREFIX;
        return this.httpRequest.globalGetMethodWithoutParams(url);
    }
    public getDesignations(designation): Observable<any> {
        const obj = { searchKeyword: designation };
        return this.httpRequest.globalGetMethod(EndPoint.GET_DESIGNATIONS, obj);
      }

    public getAumnicsNewsAndMedia(obj : any): Observable<any> {
        const headers = new HttpHeaders().set('accessToken', environment.newsAndMediadAccessToken);
        const options = { headers: headers };
       return this.httpRequest.globalGetMethod(EndPoint.GET_AUMNICS_NEWS_AND_MEDIA, obj,options);
    }
    public jobPostedByCompany(obj: any): Observable<any> {
      const headers = new HttpHeaders().set('accessToken', environment.careersAccessToken);
      const options = { headers: headers };
      return this.httpRequest.globalGetMethod(EndPoint.JOB_POSTED_BY_BY_COMPANY, obj, options);
    }
  
    // To get all new available plans info
    public getAllNewPlans(obj): Observable<any> {
      return this.httpRequest.globalGetMethod( EndPoint.GET_ALL_NEW_PLANS, obj );
    }

    public getSubscriptionPlansList() {
      return this.httpRequest.globalGetMethod(EndPoint.GET_AMY_SUBSCRIPTION_PLANS, {});
    }

    public getCertifiedPackages(obj): Observable<any>{
        return this.httpRequest.globalGetMethod( EndPoint.GET_AUMNICS_CERTIFIED_PACKAGES, obj);
    }

    public getCertifiedPackage(obj): Observable<any>{
      let visitorSessionId = this.storageService.generateUniqueId();      
      const headers = new HttpHeaders().set('visitorSessionId', visitorSessionId);
      const options = { headers: headers };
        return this.httpRequest.globalGetMethod( EndPoint.GET_AUMNICS_CERTIFIED_PACKAGE,obj,options);
    }

    public getCompanyPitches():Observable<any>{
        return this.httpRequest.globalGetMethod( EndPoint.GET_COPANY_PITCHES,{});
    }

    public getDeveloperPitches(obj):Observable<any>{
        return this.httpRequest.globalGetMethod( EndPoint.GET_DEVELOPER_PICHES,obj)
    }

    public getDevelopersOfSkillGroup(obj):Observable<any>{
        return this.httpRequest.globalGetMethod( EndPoint.GET_DEVELOPERS_OF_SKILLSGROUP, obj);
    }

    public getDeveloperProfile(obj):Observable<any>{
        return this.httpRequest.globalPostMethod( EndPoint.GET_DEVELOPER_PROFILE, obj);
    }

    public getCertifiedPackagesByCateries(): Observable<any>{
        return this.httpRequest.globalGetMethod( EndPoint.GET_PACKAGES_BY_CATEGORY, {});
    }
    
     public getSearchResults(searchKey):Observable<any> {
        return this.httpRequest.globalGetMethod( EndPoint.GET_SEARCH_RESULTS, {searchKey});
    }
    
    public getTutorialsByCategories(obj): Observable<any> {
      return this.httpRequest.globalGetMethod( EndPoint.GET_TUTORIAL_BY_CATEGORY, obj);
    }

    public getAgreementDataById(obj): Observable<any> {
      return this.httpRequest.globalGetMethod( EndPoint.GET_AGREEMENT_DATA_BY_ID, obj);
    }

    public getTopDemandCompanies(obj): Observable<any> {
      return this.httpRequest.globalGetMethod( EndPoint.GET_TOP_DEMAND_COMPANIES, obj);
    }

    public getAllPackageCategories(obj): Observable<any> {
      return this.httpRequest.globalGetMethod(EndPoint.GET_ALL_ACP_CATEGORIES, obj)
    }

    public getTopDemandChart(obj): Observable<any> {
      return this.httpRequest.globalGetMethod( EndPoint.GET_TOP_DEMAND_CHART, obj)
    }

    public getTemplatesAvailableCategoriesWithSubCategories(obj) {
      return this.httpRequest.globalGetMethod(EndPoint.GET_TEMPLATES_AVAILABLE_CATEGORIES_WITH_SUB_CATEGORIES, obj);
    }

    public getTemplates(obj): Observable<any> {
      return this.httpRequest.globalGetMethod( EndPoint.GET_TEMPLATES, obj)
    }

    public getCategoriesForTemplateFilter(obj): Observable<any> {
      return this.httpRequest.globalGetMethod(EndPoint.GET_ALL_ACP_CATEGORIES_FOR_FILTER, obj)
    }

    reverseGeocode(lat: number, lon: number): Observable<string> {
      const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`;
      
      return this.http.get<any>(nominatimUrl).pipe(map(data => {
          const cityName = data.address.city || data.address.town || data.address.village || '';
          return cityName;
        })
      );
    }
    
  clear(): void {
    this.storage.clear();
  }

  public saveUserRequest(obj):Observable<any>{
    return this.httpRequest.globalPostMethod( EndPoint.SAVE_USER_REQUEST, obj);
  }

  public verifyIndividualUserUsingOtp(obj):Observable<any>{
    return this.httpRequest.globalPutMethod( EndPoint.VERIFY_USER_USING_OTP, obj);
  }

  public saveAmyIndividualUserAddedMembers(obj):Observable<any>{
    return this.httpRequest.globalPutMethod( EndPoint.SAVE_IDIVIDUAL_USER_USING_ADDED_MEMBERS, obj);
  }

  public resendIndividualUserVerifyOtp(obj):Observable<any>{
    return this.httpRequest.globalPutMethod( EndPoint.RESEND_OTP, obj);
  }

  public validateUserAlreadyRequested(obj):Observable<any>{
    return this.httpRequest.globalGetMethod( EndPoint.GET_USER_ALREADY_REQUESTED, obj);
  }
  
  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  public loadUrlGuide(obj?) {
    this.changeUrl.emit(obj);
  }

  public loadUrlGuideEvt() {
      return this.changeUrl;
  }

  // Method to calculate contrast color based on background color
  public getContrastColor(backgroundColor: string): string {
    if(!backgroundColor) { return ''; }
    // Convert hexadecimal color to RGB
    const rgb = this.hexToRgb(backgroundColor);
    // Calculate luminance
    const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
    // Return black or white based on luminance
    return luminance > 0.5 ? '#fff' : '#fff';
  }

  // Method to convert hexadecimal color to RGB
  hexToRgb(hex: string): { r: number, g: number, b: number } {
    if(hex){
      // Remove hash character if present
      hex = hex.replace(/^#/, '');
      // Convert shorthand hex to full hex (e.g., #123 to #112233)
      if (hex.length === 3) {
        hex = hex.replace(/(.)/g, '$1$1');
      }
    }
    // Parse hex into RGB
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }

  generateUniqueId(): string {
    const storedVisitorSessionId = this.getItem('visitorSessionId');
    if (storedVisitorSessionId) {
      return storedVisitorSessionId;
    } else {
      const timestamp = Date.now().toString(36);
      const random = Math.random().toString(36).substring(2, 8);
      const visitorSessionId = timestamp + random;
      this.setItem('visitorSessionId', visitorSessionId);
      return visitorSessionId;
    }
  }

  // Function to check if a string is valid JSON
  public isValidJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * This method is used to render a Quill Delta object to HTML.
   *
   */
  public getMessageAsHtml(msgBody, isEdited = false) {
    const converter = new QuillDeltaToHtmlConverter(msgBody.ops, {});
    let html = '';
    html = converter.convert();
  
    // Return the constructed HTML
    return html || '<p>....</p>';
}


  // method to get file icons based on extension
  public getFileIcon(input, fileType): string {
    const self = this;
    let icon;
    $.each(self.fileFormatsList, function (fileIdx, list) {
      const formatsList = list.format.split(',');
      $.each(formatsList, function (extIdx, format) {
        if (input && format.toLowerCase().trim() === input.toLowerCase().trim()) {
          icon = self.fileFormatsList[fileIdx].icon;
        }
      });
    });
    if (icon === undefined && fileType !== undefined) {
      $.each(self.defaultFileFormatsList, function (fileIdx, list) {
        const formatsList = list.format.split(',');
        $.each(formatsList, function (extIdx, format) {
          if (format.toLowerCase().trim() === fileType.split('/')[0].toLowerCase().trim()) {
            icon = self.defaultFileFormatsList[fileIdx].icon;
          }
        });
      });
    }
    if (icon === undefined) {
      return 'assets/icons/files/default_data_files.svg';
    } else {
      return icon;
    }
  }
}

export class ServerStorage implements Storage {
    private data: { [key: string]: string } = {};
  
    get length(): number {
      return Object.keys(this.data).length;
    }
  
    clear(): void {
      this.data = {};
    }
  
    getItem(key: string): string | null {
        let value = this.data.hasOwnProperty(key) ? this.data[key] : null;
        return value;
    }
  
    key(index: number): string | null {
      const keys = Object.keys(this.data);
      return index >= 0 && index < keys.length ? keys[index] : null;
    }
  
    removeItem(key: string): void {
      delete this.data[key];
    }
  
    setItem(key: string, value: string): void {
      this.data[key] = value;
    }

}
