<footer class="w-full bg-gradient-to-br from-slate-50 to-indigo-50 text-slate-700">
  <!-- Main footer content -->
  <div class="max-w-7xl mx-auto py-16 px-4 md:px-4 lg:px-4">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12">
      <!-- Company info -->
      <div class="lg:col-span-2">
        <a href="/en" class="inline-block mb-2">
          <img
            src="assets/icons/Homepage/amy-logo.png"
            alt="{{ 'footer.logoAlt' | translate }}"
            class="w-auto h-10 brightness-100"
          />
        </a>
        <p class="mb-6 text-slate-600 max-w-sm">
          {{ 'footer.companyDescription' | translate }}
        </p>
        <div class="flex gap-4">
          <a href="{{sharedService.facebook}}" target="_blank" class="text-indigo-500 hover:text-indigo-700 transition-colors">
            <i class="fa-brands fa fa-facebook-f h-5 w-5"></i>
          </a>
          <a href="{{sharedService.twitter}}" target="_blank" class="text-indigo-500 hover:text-indigo-700 transition-colors">
            <i class="fa-brands fa fa-x-twitter h-5 w-5"></i>
          </a>
          <a href="{{sharedService.linkedin}}" target="_blank" class="text-indigo-500 hover:text-indigo-700 transition-colors">
            <i class="fa-brands fa fa-linkedin h-5 w-5"></i>
          </a>
          <a href="{{sharedService.instagram}}" target="_blank" class="text-indigo-500 hover:text-indigo-700 transition-colors">
            <i class="fa-brands fa fa-instagram h-5 w-5"></i>
          </a>
          <a href="{{sharedService.youtube}}" target="_blank" class="text-indigo-500 hover:text-indigo-700 transition-colors">
            <i class="fa-brands fa fa-youtube h-5 w-5"></i>
          </a>
          <a href="{{sharedService.medium}}" target="_blank" class="text-indigo-500 hover:text-indigo-700 transition-colors">
            <i class="fa-brands fa fa-medium h-5 w-5"></i>
          </a>
        </div>
      </div>

      <!-- Company -->
      <div>
        <h3 class="text-slate-900 font-semibold mb-4">{{"Footer.Company" | translate}}</h3>
        <ul class="space-y-3">
          <li>
            <a [routerLink]="['/', languageCode, 'about-us']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.About" | translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['/', languageCode, 'amy-service-provider']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.amyServiceProvider" | translate}}
            </a>
          </li>
          <li>
            <a [href]="web_url + 'en/blogs'" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.Blog" | translate}}
            </a>
          </li>
          <li>
            <a [href]="web_url + 'en/careers'" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.Careers" | translate}}
            </a>
          </li>
          <li>
            <a [href]="web_url + 'en/faq'" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.faq" | translate}}
            </a>
          </li>
          <li>
            <a [href]="web_url + 'en/newsandmedia'" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.Newsroom" | translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['/', languageCode, 'contact-us']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.ContactUs" | translate}}
            </a>
          </li>
        </ul>
      </div>

      <!-- Features -->
      <div>
        <h3 class="text-slate-900 font-semibold mb-4">{{"Footer.Features" | translate}}</h3>
        <ul class="space-y-3">
          <li>
            <a [routerLink]="['/', languageCode, 'ask-amy']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.AskAmy" | translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['/', languageCode, 'enterprise-collaboration']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.EnterpriseCollaboration" | translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['/', languageCode, 'project-management']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.ProjectManagement" | translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['/', languageCode, 'integrated-marketplace']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.IntegratedMarketplaceServices" | translate}}
            </a>
          </li>
        </ul>
      </div>

      <!-- Community -->
      <div>
        <h3 class="text-slate-900 font-semibold mb-4">{{"Footer.Community" | translate}}</h3>
        <ul class="space-y-3">
          <li>
            <a [routerLink]="['/', languageCode, 'senior-proffesionals']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.seniorProfesionals" | translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['/', languageCode, 'trusted-service-provider-partners']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.trustedPartners" | translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['/', languageCode, 'developers']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.Developers" | translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['/', languageCode, 'partners']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.Partners" | translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['/', languageCode, 'affiliates-programme']" class="text-slate-600 hover:text-indigo-700 transition-colors">
              {{"Footer.AffiliatesProgramme" | translate}}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Newsletter -->
    <div class="border-t border-slate-200 mt-12 pt-12">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
        <div>
          <h3 class="text-slate-900 text-xl font-semibold mb-2">{{ 'footer.newsletter.title' | translate }}</h3>
          <p class="text-slate-600">{{ 'footer.newsletter.description' | translate }}</p>
        </div>
        <div>
          <form class="flex" [formGroup]="emailValidations" (ngSubmit)="onSubmit()">
            <input type="text" class="flex-grow px-4 py-3 bg-white rounded-l-lg text-slate-800 focus:outline-none focus:ring-1 focus:ring-indigo-500 border border-slate-200 border-r-0" placeholder="{{'footer.newsletter.placeholder' | translate}}" formControlName="emailId" required>                        
            <button class="bg-amber-400 hover:bg-amber-500 text-slate-900 px-4 py-3 rounded-r-lg transition-colors flex items-center shadow-sm hover:shadow-md hover:shadow-amber-500/20" (click)="onSubmit()">
              {{ 'footer.newsletter.button' | translate }} <i class="arrow-right h-4 w-4 ml-2"></i>
            </button>
          </form>  
          <mat-error  *ngIf="f.emailId.touched  && f.emailId.errors?.required">Email is required</mat-error>
          <mat-error  *ngIf="f.emailId.touched && f.emailId.errors?.email">Enter a valid email address</mat-error>       
        </div>
      </div>
    </div>
  </div>

  <!-- Bottom bar -->
  <div class="border-t border-slate-200 py-4 px-6 md:px-10 lg:px-16">
    <div class="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center">
      <p class="text-slate-500 text-sm">© {{ currentYear }} Aumnics Inc. {{ 'footer.copyright' | translate }}</p>
      <div class="flex gap-6 mt-4 md:mt-0">
        <a [routerLink]="['/', languageCode, 'privacy-policy']" [queryParams]="{ policyType: appName }" class="text-slate-500 hover:text-indigo-700 text-sm">
          {{ 'footer.legal.privacy' | translate }}
        </a>
        <a  [routerLink]="['/', languageCode, 'responsible-use-policy']" [queryParams]="{ agreementType: appName }" class="text-slate-500 hover:text-indigo-700 text-sm">
          {{ 'footer.legal.terms' | translate }}
        </a>
        <a [routerLink]="['/', languageCode, 'responsible-use-policy']" [queryParams]="{ agreementType: appName }" class="text-slate-500 hover:text-indigo-700 text-sm">
          {{ 'footer.legal.cookies' | translate }}
        </a>
      </div>
    </div>
  </div>
</footer>

<p-toast></p-toast>