<mat-drawer-container>
  <mat-drawer #drawer [ngClass]="{'full-width-drawer' : showProfile || showSearch || showCountries}" mode="over" position="end" disableClose="true" [opened]="isOpened">
    <div class="d-flex justify-content-between align-items-top mx-3 my-3" *ngIf="showSearch">
      <app-mobile-search (drawerClose)="drawer.close()" class="w-100"></app-mobile-search>
    </div>
    <app-countries-listing-modal (drawerClose)="drawer.close()" *ngIf="showCountries"></app-countries-listing-modal>
    <app-profile-view-card (drawerClose)="drawer.close()" [workId]="selectedDeveloperWorkId" *ngIf="showProfile"></app-profile-view-card>
      <app-skill-groups (drawerClose)="drawer.close()" (hireContractor)="openHireContactorForm($event)" *ngIf="!isOpened && isSkillGroup && !showProfile && !showSearch && !showCountries"></app-skill-groups>
      <app-hire-contractor (drawerClose)="drawer.close()" *ngIf="isOpened && isSkillGroup && !showProfile && !showSearch && !showCountries" [selectedSkills]="selectedSkills"></app-hire-contractor>
  </mat-drawer>
  <mat-drawer-content>
      <div class="wrapper scrollableContent" id="scrollableContent" #scrollableContent [ngClass]="{'regular-scroll' : !canShowHeaderFooter()}">          
          <!-- <app-navbar [showRegularHeader]="showRegularHeader" [headerColor]="headerColor" *ngIf="canShowHeaderFooter()"></app-navbar> -->
          <app-header *ngIf="canShowHeaderFooter()"></app-header>
          <router-outlet></router-outlet>
          <amy-chat-bot *ngIf="isUIBrowser" ></amy-chat-bot>
          <app-footer *ngIf="canShowHeaderFooter()"></app-footer>


          <!-- sticky bar for homepage  -->
          <!-- <div class="sticky-bar" [ngClass]="{'show': showStickyBar}" *ngIf="canShowStickyBottom">
            <p class="paragraph-global-text">The seamless way to run your agency.</p>
            <a class="" [href]="signUrl+'get-started/u/setup-profile?returnUrl=amy'" target="_blank">
              <button class="save-btn stciky-bar-btn cfa">
                Get started now!
              </button>
              </a>
          </div>           -->
      </div>
  </mat-drawer-content>
</mat-drawer-container>

<!-- An dummy component for setting cookies only loads in browsers other than brave -->
<app-cookies-consent *ngIf="isNotBeaveBrowser && isUIBrowser"></app-cookies-consent>